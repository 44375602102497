// stag
// export const Domain = "login-aurora.invsta.io";
// export const clientId = "6TSyLPMNeSkxNmR91YNYwMTUWV2DlPyv";
// export const connection = "Aurora-stag";
// export const audience = "https://dev-6lhn21ykar.au.auth0.com/api/v2/";
// export const Apis = "https://d20kgghfsupf2w.cloudfront.net";
// export const CHECK_ENV = "stag";
// export const singup_auth =
//     "https://dev-6lhn21ykar.au.auth0.com/dbconnections/signup";
// export const all_user_Api = "https://d1rn7wclunp7ix.cloudfront.net";
// export const twilio = "https://verify1-6449-eipdfc.twil.io";
// export const investoption = "https://d1rn7wclunp7ix.cloudfront.net";
// export const updatedata = "https://d1rn7wclunp7ix.cloudfront.net";

//dev
export const Domain = "aurora-dev12.us.auth0.com";
export const clientId = "bo5tsVOiqwgUuFZCufy8jOZ2odaKvgdr";
export const audience = "https://aurora-dev12.us.auth0.com/api/v2/";
export const CHECK_ENV = "dev";
export const connection = "Aurora-dev";
export const singup_auth = "https://aurora-dev12.us.auth0.com/dbconnections/signup";
export const Apis = "https://cy7f500ia7.execute-api.ap-southeast-2.amazonaws.com/dev";
export const all_user_Api = "https://hmjgpiwsll.execute-api.ap-southeast-2.amazonaws.com/dev";
export const twilio = "https://verify1-6449-eipdfc.twil.io";
export const investoption = "https://hmjgpiwsll.execute-api.ap-southeast-2.amazonaws.com/dev";
export const updatedata = "https://hmjgpiwsll.execute-api.ap-southeast-2.amazonaws.com/dev";

//production
// export const Domain = "auth-aurora.invsta.io";
// export const clientId = "XrS4f0X4k4OkHbybYJcvQdsvlpsVflkl";
// export const audience = "https://inv-aur-pro.au.auth0.com/api/v2/";
// export const CHECK_ENV = "prod";
// export const Apis = "https://d1r4sifx7sxy5k.cloudfront.net";
// export const singup_auth =
//   "https://inv-aur-pro.au.auth0.com/dbconnections/signup";
// export const connection = "Aurora-app";
// export const twilio="https://verify1-6449-eipdfc.twil.io";
// export const all_user_Api ="https://d34k6vyc7r6bs4.cloudfront.net";
// export const investoption="https://d34k6vyc7r6bs4.cloudfront.net";
// export const updatedata="https://d34k6vyc7r6bs4.cloudfront.net";

// test
// export const Domain = "dev-chya15ix.au.auth0.com";
// export const clientId = "XxpTBWGFtRodkiRozTXtkMyhDJ6SmRSF";
// export const audience = "https://dev-chya15ix.au.auth0.com/api/v2/";
// export const Apis = "https://d2smajqgodfmwr.cloudfront.net";
// export const CHECK_ENV = "test";
// export const connection = "Aurora-test";
// export const singup_auth =
//   "https://dev-chya15ix.au.auth0.com/dbconnections/signup";
//   export const all_user_Api =
//   "https://prfv0pz9sk.execute-api.ap-southeast-2.amazonaws.com/test";
//   export const twilio="https://verify1-6449-eipdfc.twil.io";
