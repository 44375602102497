import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";
import { Auth0Provider } from "@auth0/auth0-react";
import { Domain, clientId,audience} from "./auth/Configration";
React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      // domain="login-aurora.invsta.io"
    // stag  
    // clientId="6TSyLPMNeSkxNmR91YNYwMTUWV2DlPyv"

    // dev
      //  clientId="HU1E3RfZy3Xj0uW3q3nPsQl9aao3KuI2"
      // redirectUri={window.location.origin}
      // audience="https://dev-6lhn21ykar.au.auth0.com/api/v2/"
      // scope="read:current_user update:current_user_metadata"


      //dev data
      domain={Domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      scope="read:current_user update:current_user_metadata"


    >
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
