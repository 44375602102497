import React, { Component } from "react";
import {Redirect, BrowserRouter, Route, HashRouter, Switch } from "react-router-dom";
import "./scss/style.scss";
// import "./style.css";
import "./aurora.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboards.css";
import {RecoilRoot } from "recoil";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);



// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages

class App extends Component {
  render() {
    return (
      <RecoilRoot>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            
            <Route
            
              path="/"
              
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
              {/* <Route  path="/*" component={Notfound} /> 
              <Redirect to="/*" /> */}
          </Switch>
        </React.Suspense>
      </HashRouter>
      </RecoilRoot>

    );
  }
}

export default App;
